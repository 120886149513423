/* You can add global styles to this file, and also import other style files */
@import "~ng-pick-datetime/assets/style/picker.min.css";
.actions:first-child {
  margin-right: 5px;
}

.ng-invalid.ng-dirty.form-control{
  border: 1px solid red;
}

div[ngxError] {
  color: red;
  font-size: 80%;
}

.actions:not(:first-child):not(:last-child) {
  margin-right: 5px;
  margin-left: 5px;
}

.actions:last-child {
  margin-left: 5px;
}

.required {
  display: inline-block;
  padding-left: 2px;
  color: red;
}

.cms-card {
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.4);
  padding: 0px;
  margin-top: 20px;
  border: 1px #eee solid;
  border-radius: 10px;
}
.col-xs-12.cms-card-content {
  padding: 0px;
  padding-right: 0px;
  padding-left: 0px;
  margin-top: -2px;
  text-align: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.cms-card-header {
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2em;
  height: 2.4em;
  font-size: 15px;
  color: #444;
}
.cms-card-picture {
  height: 160px;
  object-fit: cover;
}
.cms-card-body-container {
  margin-top: 10px;
  margin-bottom: 0px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
}
.cms-card-body {
  line-height: 1.5em;
  height: 6em;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: justify;
  font-size: 80%;
}
.cms-card-actions-primary {
  text-align: right;
  padding-left: 5px;
  padding-right: 15px;
}
.cms-card-actions-secondary {
  text-align: left;
  padding-left: 15px;
  padding-right: 5px;
}
.cms-card-body-font {
  font-size: 12px;
}

.form-group {
  min-height: 75px;
  margin-bottom: 0px;
}


.geoguide-category-group {
  border-color: transparent !important;
}
.geoguide-category-group .panel-heading {
  padding: 1px 3px;
}
.geoguide-category-group .panel-heading:hover {
  background-color: #415161;
}

.panel {
  box-shadow: 0 0px 0px
}

.panel-body {
  padding: 0px 0px 0px 15px
}
.panel-open .geoguide-category-group .panel-heading {
  background-color: #415161;
}
.panel-group .panel + .panel {
  margin-top: 0px;
}
.geoguide-category-group .panel-heading .geolocation-title,
.geoguide-category-group .panel-heading .geolocation-title + i {
  color: black;
  font-weight: 600;
  font-size: 16px;
}

.panel-open .geoguide-category-group .panel-heading .geolocation-title,
.panel-open .geoguide-category-group .panel-heading .geolocation-title + i,
.geoguide-category-group .panel-heading:hover .geolocation-title,
.geoguide-category-group .panel-heading:hover .geolocation-title + i {
  color: white;
}
